<template>
  <div class="twentytwenty-container"
    v-bind:style="containerStyle"
    v-on:touchstart="startSlide"
    v-on:mousedown="startSlide"
  >

    <!-- <div class="twentytwenty-tooltip" :class="{'twentytwenty-tooltip--hide': isStartedMoving}">
      Move the slider to compare the original photo with optimized
    </div> -->

    <div v-if="!isAllImagesLoaded" v-loading="true"
      style="max-width: 400px; width: 100%; height: 331px; border: 1px solid #ccc; box-sizing: border-box;"
    >
    </div>

    <!--
    <img :src="isLoaderFinished ? after : null" alt="after"
       @load="imageLoaded('after')" v-on:mousedown.prevent
       :class="{ 'hidden-image': !isAllImagesLoaded }" 
    />
    <img :src="isLoaderFinished ? before : null" alt="before"
      @load="imageLoaded('before')" v-on:mousedown.prevent v-bind:style="beforeImgStyle"
      :class="{ 'hidden-image': !isAllImagesLoaded }" 
    />
    -->

    <!--
    <img v-lazy="after" alt="after" v-on:mousedown.prevent :class="{ 'hidden-image': !isAllImagesLoaded }" />
    <img v-lazy="before" alt="before" v-on:mousedown.prevent v-bind:style="beforeImgStyle"
      :class="{ 'hidden-image': !isAllImagesLoaded }" 
    />
    -->


    <!-- <img v-lazy="image" -->
    <img :src="isLoaderFinished ? image : null" @load="setDimensions"
      :class="{ 'hidden-image': !isAllImagesLoaded }" class="twentytwenty-container__main-image" ref="mainImage"
    />
    <!-- <img :src="image" :class="{ 'hidden-image': !isAllImagesLoaded }" /> -->

    <img src="/public-images/pattern.png" v-on:mousedown.prevent :class="{ 'hidden-image': !isAllImagesLoaded }" />
    <!-- <img src="/public-images/pattern.png" v-on:mousedown.prevent @load="setDimensions" /> -->

    <img :src="getAddedImageUrl" v-on:mousedown.prevent v-bind:style="beforeImgStyle"
      :class="{ 'hidden-image': !isAllImagesLoaded }"
    />

    <div v-if="isAllImagesLoaded" class="twentytwenty-handle" :style="handleStyle">
        <div class="twentytwenty-arrow-left"></div>
        <div class="twentytwenty-arrow-right"></div>
    </div>
  </div>
</template>

<script>

import { Loading } from 'element-ui';
import VueLazyload from 'vue-lazyload'

import Vue from 'vue';
Vue.use(Loading.directive);

Vue.use(VueLazyload);

export default {
  data () {
    return {
      imgOffset: null,
      slideOffset: this.offset,
      sliding: false,
      containerStyle: {},
      overlayStyle: {},

      isStartedMoving: false,

      isAllImagesLoaded: false,
      // isAllImagesLoaded: true,
    }
  },

  props: {
    isLoaderFinished: {
      type: Boolean,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    id: {type: Number, required: true},

    // before: {
    //   type: String,
    //   required: true
    // },
    // after: {
    //   type: String,
    //   required: true
    // },
    offset: {
      type: [String, Number],
      default: 0.5,
      validator: (value) => {
        return (value > 0 && value <= 1)
      }
    }
  },

  mounted () {
    document.addEventListener("touchmove", this.moveSlide)
    document.addEventListener("touchend", this.endSlide)
    document.addEventListener("mousemove", this.moveSlide)
    document.addEventListener("mouseup", this.endSlide)
    window.addEventListener("resize", this.resize)

    // const handler = ({ src }) => {
    //   if (src !== this.image) return;
    //   console.log('src', src)
    //   this.$Lazyload.$off('loaded', handler)

    //   // this.$nextTick(() => {
    //   //   this.$nextTick(() => {
    //   //     this.setDimensions();
    //   //   });
    //   // });
    //   setTimeout(this.setDimensions, 2000);
    // }

    // this.$Lazyload.$on('loaded', handler);
  },

  methods: {
    setDimensions () {
      if (!this.isLoaderFinished) return;

      // const img = this.$el.querySelector("img")
      // const img = this.$el.querySelector(".twentytwenty-container__main-image")
      const img = this.$refs.mainImage;

      this.imgOffset = img.getBoundingClientRect()
      this.containerStyle = { width: `${this.w}px`, height: `${this.h}px` };

      this.$nextTick(() => {
        this.isAllImagesLoaded = true;
      });
    },

    startSlide (event) {
      this.sliding = true
      this.moveSlide(event)
      this.overlayStyle = { opacity: 0 }
      this.isStartedMoving = true
    },

    moveSlide (event) {
      if (this.sliding) {
        var x = (event.touches ? event.touches[0].pageX : event.pageX) - this.imgOffset.left
        x = (x < 0) ? 0 : ((x > this.w) ? this.w : x)

        this.slideOffset = (x / this.w)
      }
    },

    endSlide () {
      this.sliding = false
      this.overlayStyle = {}
    },

    resize () {
      this.containerStyle = {};
      this.$nextTick(() => this.setDimensions());
    }
  },

  computed: {
    getAddedImageUrl() {
      if (this.id % 2 === 0) return '/public-images/myape_for_galleries.png';
      return '/public-images/mymask_for_galleries.png';
    },

    beforeImgStyle () {
      return { clip: `rect(0, ${this.x}px, ${this.h}px, 0)` }
    },

    handleStyle () {
      const size = 40;
      return {
        width: `${size}px`,
        height: `${size}px`,
        top:  `calc(50% - ${size/2}px)`,
        left: `calc(${this.slideOffset*100}% - ${size/2}px)`
      }
    },

    x () {
      return this.w * this.slideOffset
    },

    w () {
      if (this.imgOffset)
        return this.imgOffset.width
    },

    h () {
      if (this.imgOffset)
        return this.imgOffset.height
    }
  },

  beforeDestroy () {
    document.removeEventListener("touchmove", this.moveSlide)
    document.removeEventListener("touchend", this.endSlide)
    document.removeEventListener("mousemove", this.moveSlide)
    document.removeEventListener("mouseup", this.endSlide)
    window.removeEventListener("resize", this.resize)
  }
}
</script>

<style lang="scss" scoped>


.hidden-image {
  opacity: 0;
}

.twentytwenty-container {
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
}
.twentytwenty-container img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  user-select: none;
  z-index: 20;
}

.twentytwenty-container .twentytwenty-tooltip {
  left: 50%;
  position: absolute;
  z-index: 50;
  background: #409EFF;
  color: #fff;
  margin: 0 auto 20px 13px;
  max-width: 300px;
  padding: 15px 15px 15px 25px;
  box-shadow: 0 5px 2px rgba(0,0,0,.3);
  box-sizing: border-box;
  transition: 0.2s;

  &.twentytwenty-tooltip--hide {
    opacity: 0;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #409EFF transparent transparent;
  }
}

.twentytwenty-container .twentytwenty-overlay {
  z-index: 25;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
}
.twentytwenty-container .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container .twentytwenty-overlay .twentytwenty-after-label {
  user-select: none;
  position: absolute;
  font-size: 0.8em;
  top: calc(50% - 0.4em - 5px);
  padding: 10px;
  background: rgba(255, 255, 255, 0.4);
  color: white;
}
.twentytwenty-container .twentytwenty-overlay .twentytwenty-before-label {
  left: 0;
}
.twentytwenty-container .twentytwenty-overlay .twentytwenty-after-label {
  right: 0;
}
.twentytwenty-container:hover > .twentytwenty-overlay {
  opacity: 1;
}
.twentytwenty-container .twentytwenty-handle {
  cursor: move;
  z-index: 30;
  position: absolute;
  background: none;
  border: 4px solid white;
  border-radius: 50px;
  margin-left: -4px;
  margin-top: -4px;
  user-select: none;
}
.twentytwenty-container .twentytwenty-handle:before, .twentytwenty-container .twentytwenty-handle:after {
  content: "";
  border: 2px solid white;
  height: 9999px;
  position: absolute;
  left: calc(50% - 2px);
}
.twentytwenty-container .twentytwenty-handle:before {
  top: 40px;
}
.twentytwenty-container .twentytwenty-handle:after {
  bottom: 40px;
}
.twentytwenty-container .twentytwenty-arrow-right,
.twentytwenty-container .twentytwenty-arrow-left {
  user-select: none;
  position: relative;
  width: 0;
  height: 0;
}
.twentytwenty-container .twentytwenty-arrow-right {
  bottom: 10px;
  left: 23px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid white;
}
.twentytwenty-container .twentytwenty-arrow-left {
  top: 10px;
  left: 7px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
}















.twentytwenty-container {
  // max-height: 100% !important;
    
  img {
    // min-height: 400px;
    // max-height: 400px;
    width: 100%;
    
    // object-fit: cover;
  }
}

.twentytwenty-overlay {
  background: transparent;
  opacity: 1 !important;
  position: absolute;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: 0.5s;
    z-index: 1;
  }

  // .twentytwenty-container:hover ^[-1..-1] {
  //   opacity: 0.8 !important;

  //   &:after {
  //     opacity: 1;
  //   }
  // }
  
  .twentytwenty-before-label,
  .twentytwenty-after-label {
    z-index: 2;
    font-family: 'Roboto Mono', monospace;
    bottom: 0;
    top: auto;
    font-size: 18px;

    /* background: #e6f3ff; */
    // background-color: rgba(255, 255, 255, 0.3);

    background: rgba(46, 64, 78, .8);
  }

  .twentytwenty-after-label {
    background: rgba(64, 158, 255, 0.8); 
  }
}
</style>
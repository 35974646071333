<template>
  <div>


    <div class="buying-section">
      <div class="buying-section__main-section">

        <h2 style="margin-bottom: 70px;">Get Your Own Gallery</h2>
        <!-- <p><b>571</b> left to be minted</p> -->
        <!-- <p>There will only ever be 5000 in existance, and <b>{{ totalSupply ? totalSupply : '...' }}</b> have already been claimed. That means there are <b>{{ totalSupply ? 5000 - totalSupply : '...' }}</b> left to be created.</p> -->

        <div class="main-content__progress-section-wrapper">
          <div class="main-content__progress-section__header">
            <h3>Sale progress</h3>
            <h3>Only {{getFormattedLeftNumber}} left!</h3>
          </div>

          <div class="main-content__progress-section__progressbar">
            <div :style="{ width: getProgressbarWidth + '%' }"></div>
            <span v-if="getProgressbarWidth >= 100">Sold!</span>
          </div>
        </div> <!-- /.main-content__progress-section-wrapper -->


        <div style="margin-bottom: 100px;">
          <el-button v-if="hasMetamask" @click="connectMetamask"
            :class="{ 'address-connected': web3.accounts }"
          >
            <img src="@/assets/metamask.png" class="button__metamask-image">

            {{ getTextForMetamaskButton }}
          </el-button>

          <p v-else>Please install metamask</p>
        </div>

        <div v-if="web3.accounts">
          <!-- <p>
            Account: {{ web3.accounts ? web3.accounts[0] : 'huy' }}
            <br>
            Network Id: {{ web3.networkId }}
          </p> -->

          <h3 style="margin-bottom: 20px;">
            Choose your Hashmasks or Apes for Galleries <!-- (up to 5) -->
          </h3>

          <ul class="buying-section__notes-list">
            <!-- The sale contract will just track how many items you are allowed to mint based on what Hashmasks you own now. -->
            <li>You will of course still own your Hashmask/Ape at the end of this process.</li>
            <li>You can buy only <b>up to 5</b> Galleries per transaction.</li>
          </ul>

          <div class="buying-section__masks-list buying-section__masks-list--select">

            <el-tabs v-model="currentNftTab">

              <el-tab-pane label="Hashmasks" name="hashmask" :disabled="chosenNftsForBuying.bayc.length > 0">
                <el-card v-loading="isConnectingMetamask" v-for="hashmask in ownedNfts.hashmask" :key="`hashmask-${hashmask.id}`"
                  @click.native="chooseNft(hashmask, 'hashmask')"
                  :class="{ 
                    'buying-section__select-mask--active': chosenNftsForBuying.hashmask.includes(hashmask.id),
                    'buying-section__select-mask--disabled': !chosenNftsForBuying.hashmask.includes(hashmask.id) && chosenNftsForBuying.hashmask.length === 5,
                    'buying-section__select-mask--has-gallery': hashmask.isConnectedToGallery,
                    'buying-section__select-mask--wait': hashmask.isWaitBlockchainConfirmation,
                  }"
                >
                  <div class="buying-section__select-mask__checkmark">✔</div>
                  <img :src="getHashmaskImageFromTokenId(hashmask.id)">

                  <div class="buying-section__select-mask__text">
                    <h4>Mask #{{ hashmask.id }}</h4>
                    <h5>{{ hashmask.name }}</h5>
                  </div>
                </el-card>

                <p v-if="!isConnectingMetamask && ownedNfts.hashmask.length === 0">
                  You don't own any Hashmasks. Go get one!
                </p>
              </el-tab-pane>

              <el-tab-pane label="BAYC" name="bayc" :disabled="chosenNftsForBuying.hashmask.length > 0">
                <el-card v-loading="isConnectingMetamask" v-for="ape in ownedNfts.bayc" :key="`ape-${ape.id}`"
                  @click.native="chooseNft(ape, 'bayc')"
                  :class="{ 
                    'buying-section__select-mask--active': chosenNftsForBuying.bayc.includes(ape.id),
                    'buying-section__select-mask--disabled': !chosenNftsForBuying.bayc.includes(ape.id) && chosenNftsForBuying.bayc.length === 5,
                    'buying-section__select-mask--has-gallery': ape.isConnectedToGallery,
                    'buying-section__select-mask--wait': ape.isWaitBlockchainConfirmation,
                  }"
                >
                  <div class="buying-section__select-mask__checkmark">✔</div>
                  <img :src="ape.image">
                  <div class="buying-section__select-mask__text">
                    <h4>Ape #{{ ape.id }}</h4>
                  </div>
                </el-card>

                <p v-if="!isConnectingMetamask && ownedNfts.bayc.length === 0">
                  You don't own any Apes. Go get one!
                </p>
              </el-tab-pane>

            </el-tabs>

          </div> <!-- buying-section__masks-list -->

        </div> <!-- v-if="web3.accounts" -->
      </div>


      <div v-if="web3.accounts" class="buying-section__buyed" v-loading="isConnectingMetamask">
        <h3>
          You have {{ balanceOf === null ? '...' : balanceOf }} Galleries
        </h3>
      </div>

      <!-- <div v-if="web3.accounts" class="buying-section__buyed" v-loading="isConnectingMetamask">
        <h3 style="margin-bottom: 20px;">
          Your Hashmasks and Apes with Galleries
        </h3>
        <div v-if="isConnectingMetamask || getNftsWithGalleries.length > 0" class="buying-section__masks-list buying-section__masks-list--buyed">
          <el-card v-for="(nft, i) in getNftsWithGalleries" :key="`with-gallery-${i}-${nft.id}`">
            <div class="buying-section__select-mask__checkmark">✔</div>
            <img :src="nft.image">

            <div class="buying-section__select-mask__text">
              <h4>{{ nft.title }}</h4>
            </div>
          </el-card>
        </div>
        <div v-else class="buying-section__no-nft">You don't own any Galleries. Go get one!</div>
      </div> -->


    </div> <!-- buying-section -->


    <div class="buying-panel" v-if="getChosenNftsForBuyingPanel.length > 0"
      :class="{ 'buying-panel--has-buy-button': isShowBuyButton }"
    >

      <div class="buying-panel__chosen-nfts">
        <img v-for="nft in getChosenNftsForBuyingPanel" :key="`chosen-${nft.id}`"
          :src="nft.image"
        />
      </div>

      <div class="buying-panel__content">

        <el-radio-group v-model="currencyForPaying" class="buying-panel__currency-selector">
          <el-radio-button label="ETH"></el-radio-button>
          <el-radio-button label="NCT"></el-radio-button>
        </el-radio-group>

        <el-button v-if="isShowBuyButton" @click="buy" class="buying-panel__buy-button">
          {{getBuyButtonText}}
        </el-button>

      </div>
      
    </div> <!-- /.buying-panel -->


    <!-- :visible.sync="dialogVisible" -->
    <el-dialog
      :visible.sync="isShowNctPopup"
      title="Buy Galleries with NCT"
      width="600px"
      class="nct-popup"
    >

      <el-collapse v-model="nctPopupActiveCollapse" accordion>

        <!-- <el-collapse-item title="Unlock NCT" name="1" :disabled="true"> -->
        <el-collapse-item title="Unlock NCT" name="1" disabled
          :class="{'my-disabled': nctPopupActiveCollapse == '2'}"
        >
          <p>
            By design, smart contracts can’t access your tokens unless you approve access from
            your end. By ‘unlocking’ your NCT, you are give permission to the Bored Hash
            smart contract to spend your NCT. For ‘unlocking’ you must complete
            a free (plus gas) transaction.
          </p>
          <el-button @click="unlockNct">{{ getTextForNctUnlockingButton }}</el-button>
        </el-collapse-item>

        <el-collapse-item title="Buy Galleries" name="2" disabled
          :class="{'my-disabled': nctPopupActiveCollapse == '1'}"
          class="nct-popup__second-collapse"
        >
          <p>You successfully unlocked your NCT, now you can buy Galleries.</p>
          <el-button @click="buyWithNct">{{getBuyButtonText}}</el-button>
        </el-collapse-item>

      </el-collapse>


      <!-- https://help.1inch.io/en/articles/4585113-why-do-i-need-to-approve-my-tokens-before-a-trade -->

      <!--
        Listing your item...
        Just accept the signature request and wait for us to process your listing.
      -->

      <!--
        Initialize your account first!

        To allow OpenSea to sell items in your account, you must first complete a free (plus gas) transaction.

        Keep this tab open while we wait for the blockchain to confirm your action. This only needs to be done once for your account!

      -->

      <!--
      Approve
      Approve 0x contract to transfer your NFT
      Approve

      Sign sell order
      Sign sell transaction
      Sign
      -->

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false">Confirm</el-button>
      </span> -->
    </el-dialog>

  </div>
</template>

<script>

import Web3Library from 'web3';
import axios from 'axios';
import {
  Button, Card, Loading, Select, Option, RadioGroup, RadioButton, Dialog, Tabs,
  TabPane, Collapse, CollapseItem,
} from 'element-ui';

import Vue from 'vue';
Vue.use(Loading.directive);

export default {
  name: 'BuyingSection',
  components: {
    'el-button': Button,
    'el-card': Card,
    'el-select': Select,
    'el-option': Option,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-dialog': Dialog,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
  },
  data() {
    return {
      currentNftTab: 'hashmask', // or bayc

      ownedNfts: {
        hashmask: [],
        bayc: [],
      },

      chosenNftsForBuying: {
        hashmask: [],
        bayc: [],
      },

      currencyForPaying: 'ETH',
      buyingStatus: null,

      isShowNctPopup: false,
      nctPopupActiveCollapse: "1",
      unlockingNctStatus: null,

      // todo: обновить перед релизом
      nctMultipler: 200000,
      ethPrice: 0.06,

      totalSupply: null,
      balanceOf: null,

      isLoadingMainBlockhainData: true,
      isConnectingMetamask: false,
    };
  }, // data
  mounted() {
    if (this.isLoadingMainBlockhainData) {
      if (this.web3.networkId) {
        this.getBlockchainData();
      }

      // if (this.web3.accounts) {
      //   this.getBlockchainData();
      // }
    }
  },
  watch: {
    // '$store.state.contractInstance': {
    'web3.networkId': {
      handler(newWeb3, oldWeb3) {
        console.log('watch web3')
        if (this.isLoadingMainBlockhainData) {
          this.getBlockchainData();
        }
      },
      deep: true
    },
  },
  methods: {
    getEnv(envKey) {
      return process.env[envKey];
    },

    getHashmaskImageFromTokenId(hashmaskId) {
      const startingIndex = 10141;
      const imageId = (hashmaskId + startingIndex) % 16384;
      return `https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/${imageId}.png`;
    },

    // type === 'hashmask' || 'bayc'
    // nftObject === {id, isConnectedToGallery, isWaitBlockchainConfirmation}
    chooseNft(nftObject, type) {
      if (this.isConnectingMetamask === true) return;
      if (nftObject.isConnectedToGallery) return;
      if (nftObject.isWaitBlockchainConfirmation) return;

      const index = this.chosenNftsForBuying[type].indexOf(nftObject.id);
      if (index !== -1) {
        this.chosenNftsForBuying[type].splice(index, 1);
        return;
      }

      if (this.chosenNftsForBuying[type].length === 5) return;
      this.chosenNftsForBuying[type].push(nftObject.id);
    },

    async getBlockchainData() {
      const _totalSupply = await this.web3.galleriesContractInstance.methods.totalSupply().call();
      this.totalSupply = Number(_totalSupply);
      this.isLoadingMainBlockhainData = false;

      // await this.connectMetamask(); // todo: remove; только для теста, потом убрать
    },

    async connectMetamask() {
      // if (this.web3.networkId != 4) {
      //   alert(`Right now we are testing on Rinkeby. Mainnent is coming soon. Your current network id: ${this.web3.networkId}`)
      //   return;
      // }
      if (this.web3.networkId != 1) {
        alert(`Please switch your wallet to the Ethereum Mainnet and reload the page. Your current network id: ${this.web3.networkId}`)
        return;
      }

      if (this.web3.accounts) return;
      if (this.isLoadingMainBlockhainData) return;
      if (this.isConnectingMetamask) return;
      this.isConnectingMetamask = true;

      await this.$store.dispatch('registerWeb3Accounts');

      console.log('NCT Balance:', this.web3.nctBalance);
      console.log('NCT Allowance:', this.web3.nctAllowance);

      await this.retrieveOwnedNfts('masksContractInstance', 'hashmask');
      await this.retrieveOwnedNfts('baycContractInstance', 'bayc');

      const balanceOfString = await this.web3.galleriesContractInstance.methods.balanceOf(this.web3.accounts[0]).call();
      this.balanceOf = Number(balanceOfString);

      this.isConnectingMetamask = false;
    },

    async retrieveApeImageUrl(apeId) {
      const url = `https://us-central1-bayc-metadata.cloudfunctions.net/api/tokens/${apeId}`;
      const apeMetadata = await axios.get(url);
      return apeMetadata.data.image;
    },

    async retrieveOwnedNfts(contractName, nftType) {
      let currentIndex = 0;
      let isNftsEnded = false;

      while (!isNftsEnded) {
        try {
          const tokenOfOwnerByIndex = this.web3[contractName].methods.tokenOfOwnerByIndex;
          const nftId = await tokenOfOwnerByIndex(this.web3.accounts[0], currentIndex).call();

          const isConnectedToGallery = await this.web3.galleriesContractInstance.methods.isNftIdReserved(nftType, nftId).call();

          const newNft = {
            id: Number(nftId),
            isConnectedToGallery,
            // image, || for Apes
            // name,  || for Hashmasks
          }

          if (nftType === 'bayc') {
            try {
              newNft.image = await this.retrieveApeImageUrl(nftId);
            }
            catch(e) {
              newNft.image = 'https://via.placeholder.com/130?text=x';
            }
          }
          if (nftType === 'hashmask') {
            const maskName = await this.web3.masksContractInstance.methods.tokenNameByIndex(nftId).call();
            newNft.name = maskName ? maskName : 'Unnamed';
          }

          this.ownedNfts[nftType].push(newNft);
          currentIndex += 1;
        }
        catch (e) {
          console.log(`retrieveOwnedNfts (${nftType}) CATCH`)
          isNftsEnded = true;
        }
      }
    },

    // async retrieveNftsWithHashgalleries() {
    //   const eventsParams = { fromBlock: 0, toBlock: 'latest', filter: {ownerAddress: this.web3.accounts[0]} };
    //   const pastEvents = await this.web3.galleriesContractInstance.getPastEvents('NftReserved', eventsParams);

    //   this.nftsWithHashgalleries.hashmask = pastEvents.filter(x => x.returnValues.nftType === 'hashmask').map(event => Number(event.returnValues.nftId));
    //   this.nftsWithHashgalleries.bayc = pastEvents.filter(x => x.returnValues.nftType === 'bayc').map(event => Number(event.returnValues.nftId));
    // },

    buy() {
      // 56 - BSC Mainnet
      // 97 - BSC Testnet
      // if (this.web3.networkId !== 56 && this.web3.networkId !== 97) {
      //   alert("Cool. Cool. That's the Eth network \u2014 you know the one with slow txs and high fees? What you want is to connect to Binance Chain (https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain).");
      //   return;
      // }

      if (this.chosenNftsForBuying[this.currentNftTab].length > 5) return;
      if (this.chosenNftsForBuying[this.currentNftTab].length < 1) return;

      if (this.currencyForPaying === 'ETH') this.buyWithEth();
      if (this.currencyForPaying === 'NCT') this.showPopupForUnlockingNct();
    },

    showPopupForUnlockingNct() {
      if (this.web3.nctBalance < this.getAmount) {
        const formattedCurrentBalance = this.getFormattedNumber(this.web3.nctBalance.toFixed(0));
        const formattedRequiredAmount = this.getFormattedNumber(this.getAmount);
        alert(`Your NCT balance (${formattedCurrentBalance}) is insufficient. Required amount: ${formattedRequiredAmount}`)
        return;
      }

      if (this.web3.nctAllowance >= this.getAmount && this.web3.nctBalance >= this.getAmount) {
        this.buyWithNct();
        return;
      }

      this.isShowNctPopup = true;
    },

    unlockNct() {
      const value = Web3Library.utils.toWei(String(this.getAmount), 'ether');

      const galleriesAddress = this.getEnv('VUE_APP_GALLERIES_ADDRESS');
      const method = this.web3.nctContractInstance.methods.increaseAllowance(galleriesAddress, value);
      const sendParams = { from: this.web3.accounts[0] };
      this.sendTransactionToSmartContract(method, sendParams, 'unlockingNctStatus', null);
    }, // unlockNct

    buyWithNct() {
      const method = this.web3.galleriesContractInstance.methods.mintNFTWithNCT(
        this.chosenNftsForBuying[this.currentNftTab],
        this.currentNftTab,
      );
      const sendParams = { from: this.web3.accounts[0] };

      this.$store.dispatch('decreaseNctBalanceAndAllowance', this.getAmount);
      this.sendTransactionToSmartContract(method, sendParams, 'buyingStatus', this.callbackForBuyingGalleries);
    }, // buyWithNct

    callbackForBuyingGalleries() {
      for (const connectedNftId of this.chosenNftsForBuying[this.currentNftTab]) {
        const nftIndex = this.ownedNfts[this.currentNftTab].findIndex(x => x.id === connectedNftId);
        // this.ownedNfts[this.currentNftTab][nftIndex].isConnectedToGallery = true;
        this.ownedNfts[this.currentNftTab][nftIndex].isWaitBlockchainConfirmation = true;
      }

      // this.balanceOf += this.chosenNftsForBuying[this.currentNftTab].length;

      setTimeout(() => {
        this.isShowNctPopup = false;
        this.chosenNftsForBuying[this.currentNftTab] = [];
        this.buyingStatus = null;
      }, 1000)

      // console.log(`Successfully created: ${seed} || receipt:`, receipt)
      // Transaction was accepted into the blockchain, let's redraw the UI
      // getZombiesByOwner(userAccount).then(displayZombies);
    }, // callbackForBuyingGalleries

    buyWithEth() {
      const value = Web3Library.utils.toWei(String(this.getAmount), 'ether');
      const method = this.web3.galleriesContractInstance.methods.mintNFTWithETH(
        this.chosenNftsForBuying[this.currentNftTab],
        this.currentNftTab,
      );
      const sendParams = { from: this.web3.accounts[0], value: value };
      this.sendTransactionToSmartContract(method, sendParams, 'buyingStatus', this.callbackForBuyingGalleries);
    }, // buyWithEth

    sendTransactionToSmartContract(method, sendParams, statusVariable, callback) {
        const _currentNftTab = this.currentNftTab;
        const _currentNfts = this.chosenNftsForBuying[this.currentNftTab];

        method.send(sendParams)
        // .on("sent", function(receipt) {
        // })
        .on("sending", (receipt) => {
          this[statusVariable] = 'sending';
        })
        .on("transactionHash", (receipt) => {
          console.log('transactionHash', receipt)
          this[statusVariable] = 'transactionHash';

          // we need callback only for buying
          if (callback !== null) {
            callback();
          }
        })
        .on("receipt", (receipt) => {
          if (callback !== null) {
            for (const connectedNftId of _currentNfts) {
              const nftIndex = this.ownedNfts[_currentNftTab].findIndex(x => x.id === connectedNftId);
              this.ownedNfts[_currentNftTab][nftIndex].isConnectedToGallery = true;
              this.ownedNfts[_currentNftTab][nftIndex].isWaitBlockchainConfirmation = false;
            }

            this.balanceOf += _currentNfts.length;
            this.totalSupply += _currentNfts.length;
          }

          // we need to wait for blockchain confirmations only for unlocking NCT
          if (statusVariable === 'unlockingNctStatus') {
            console.log('receipt', receipt)
            this.unlockingNctStatus = 'receipt';
            this.nctPopupActiveCollapse = '2';
            // this[statusVariable] = 'receipt';
          }
        })
        .on("error", (error) => {
          // const formattedError = error.message.replace('Error: [ethjs-query] while formatting outputs from RPC', '')
          let formattedError = error.message.slice(
            error.message.indexOf(`"reason":"`),
            error.message.indexOf(`"},"stack"`)
          );
          formattedError = formattedError.replace(`"reason":"`, '');

          alert(formattedError ? `Error: ${formattedError}` : error.message)
          this[statusVariable] = null;

          // console.log('error', error) // metamask itself is logging all errors
        });
    }, // sendTransactionToSmartContract

    getFormattedNumber(rawNumber) {
      if (rawNumber < 1000) return rawNumber;
      const stringNumber = String(rawNumber);
      return stringNumber.slice(0, stringNumber.length - 3) + "," + stringNumber.slice(-3);
    },

  }, // methods
  computed: {

    hasMetamask() {
      return Boolean(window.ethereum);
    },

    web3 () {
      return this.$store.state.web3;
    },

    getChosenNftsForBuyingPanel() {
      let result = [];

      if (this.chosenNftsForBuying[this.currentNftTab].length > 0) {
        result = this.chosenNftsForBuying[this.currentNftTab].map((id) => {
          let image;
          if (this.currentNftTab === 'hashmask') image = this.getHashmaskImageFromTokenId(id);
          else image = this.ownedNfts.bayc.find(x => x.id == id).image;

          return {id, image};
        });
      }

      return result;
    }, // getChosenNftsForBuyingPanel

    getProgressbarWidth() {
      // const totalSupply = this.totalSupply;
      const totalSupply = 643;
      if (totalSupply === 0) return 0;

      const maxTokensSupply = 4000;
      const widthInPercents = (totalSupply / maxTokensSupply) * 100;
      return widthInPercents;
    },

    getFormattedLeftNumber() {
      const maxTokensSupply = 4000;
      const leftRawNumber = maxTokensSupply - this.totalSupply;
      return this.getFormattedNumber(leftRawNumber);
    },

    getAmount() {
      if (!this.isShowBuyButton) return;

      let amount = this.ethPrice * (this.getChosenNftsForBuyingPanel.length);
      if (this.currencyForPaying === 'NCT') amount = amount * this.nctMultipler;
      return amount;
    },

    getBuyButtonText() {
      if (this.buyingStatus === null) return `Buy now for ${this.getFormattedNumber(this.getAmount)} ${this.currencyForPaying}`;
      if (this.buyingStatus === 'sending') {
        // return 'Waiting for blockchain confirmation...';
        return 'Waiting for your confirmation...';
        // return 'Sending transaction...';
      }

      // if (this.buyingStatus === 'transactionHash') return 'Sending transaction...'
      if (this.buyingStatus === 'transactionHash') return 'Transaction sended!'
      // if (this.buyingStatus === 'receipt') return 'Transaction sended!'
    },

    isShowBuyButton() {
      return this.getChosenNftsForBuyingPanel.length !== 0;
    },

    getTextForNctUnlockingButton() {
      if(this.unlockingNctStatus === null) return `Unlock ${this.getFormattedNumber(this.getAmount)} NCT`;
      if(this.unlockingNctStatus === 'sending') return 'Waiting for your confirmation...';
      if(this.unlockingNctStatus === 'transactionHash') return 'Waiting for blockchain confirmations...';
      // if(this.unlockingNctStatus === 'receipt') return 'Waiting for blockchain confirmations...';
    },

    getTextForMetamaskButton() {
      if (this.web3.accounts) return this.web3.accounts[0].slice(0, 6) + '...' + this.web3.accounts[0].slice(-4);
      return this.isConnectingMetamask ? 'Connecting...' : 'Connect Wallet';
    },

    // getStatsData() {
    //   return statsData;
    // },
  }, // computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .buying-section {
    // background-color: #ddd;
    // background: rgba(227, 230, 238, 0.7);

    // padding-top: 45px;
    // padding-bottom: 100px;
    padding-top: 100px;

    text-align: center;

    & > .buying-section__main-section {
      padding-bottom: 100px;
    }
  }

  .buying-section__buyed {
    // background-color: rgb(29, 29, 29);
    background: rgba(227, 230, 238, 0.7);

    padding-top: 100px;
    padding-bottom: 100px;

    // color: #fff;
    // h3 {
    //   color: #fff;
    // }
  }

  .buying-section__no-nft {
    font-size: 15px;
    // color: rgba(0, 0, 0, 0.7);
  }

  ul.buying-section__notes-list {
    list-style-position: outside;
    max-width: 600px;
    text-align: left;
    font-size: 15px;
    line-height: 27px;
    padding-left: 30px;

    margin: auto;
    margin-bottom: 20px;
  }

  .buying-section__masks-list--select {
    .el-card {
      &:not(.buying-section__select-mask--disabled):not(.buying-section__select-mask--has-gallery):not(.buying-section__select-mask--wait) {
        cursor: pointer;
        transition: all 0.1s;
        &:hover {
          box-shadow: none;
          transform: translateY(-4px);
        }
      }
    }
  }

  .buying-section__masks-list--buyed {
    .el-card {
      .el-card__body {
        padding: 0;
      }
      img {
        // max-width: calc(100% + 40px);
        // margin-left: -20px;
        // margin-right: -20px;
        // margin-top: -20px;
      }

      background: none;
      box-shadow: none;
    }
  }

  .buying-section__masks-list {
    // margin: 0 -160px 100px;
    // margin: 0 -160px 0;
    // border: 2px solid rgba(0, 0, 0, 0);

    .el-tabs__header {
      max-width: 300px;
      margin: 40px auto 20px;

      .el-tabs__item  {
        width: 150px;
        font-family: "Inconsolata", monospace;
        font-size: 19px;
      }
    }

    // buying-section__masks-list--buyed
    &--buyed,
    .el-tab-pane {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      max-width: 1220px;
      margin: auto;
    }

    .el-card {
      // width: 230px;
      width: 170px;
      // margin: 20px;
      margin: 15px;
      position: relative;
      overflow: visible;
      text-align: left;
    }

    .el-card__body {
      padding-bottom: 10px;
      // padding: 0px 0 10px;
    }

    img {
      display: block;
      max-width: 100%;
      margin-bottom: 10px;
    }

    h4, h5 {
      margin: 0;
      font-size: 14px;
      line-height: 1.8em;
    }

    h5 {
      font-weight: normal;
    }
  } // .buying-section__masks-list

  .buying-section__select-mask--disabled {
    opacity: 0.3;
  }

  .buying-section__select-mask--has-gallery,
  .buying-section__select-mask--wait {
    position: relative;

    &.el-card.is-always-shadow {
      box-shadow: none;
    }

    .el-card__body {
      opacity: 0.4;

      img {
        opacity: 0.2;
      }
    }

    &:after {
      height: 100%;
      position: absolute;
      top: 20px;
      color: #000;
      width: 100%;
      text-align: center;
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 25px;
      font-family: "Inconsolata", monospace;
      font-size: 20px;
    }
  }

  .buying-section__select-mask--has-gallery:after {
    content: "Already connected to Gallery";
  }

  .buying-section__select-mask--wait:after {
    content: "Waiting for blockchain confirmation";
  }

  .buying-section__select-mask__text {
    // padding: 0 20px;
  }

  .buying-section__select-mask--active {
    box-shadow: none !important;
    border-color: #409EFF;
    border-radius: 0;
    background: #ecf5ff;

    // img {
    //   margin-left: 20px;
    //   margin-right: 20px;
    //   margin-top: 20px;
    //   max-width: calc(100% - 40px);
    // }
  }

  .buying-section__select-mask__checkmark {
    opacity: 0;
    transition: all 0.2s;
    .buying-section__select-mask--active & {
      opacity: 1;
    }

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -10px;
    top: -10px;

    // border-radius: 3px;
    border-radius: 50%;
    background: #409EFF;
    color: #fff;

    width: 25px;
    height: 25px;
    font-size: 15px;
  }

  .buying-panel {
    left: 0;
    right: 0;
    position: fixed;
    height: 96px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(251, 253, 255);
    bottom: 0px;
    border-top: 1px solid rgb(229, 232, 235);
    padding: 0px 24px;
    width: 100%;
    z-index: 2000;
    box-sizing: border-box;

    @media (max-width: 650px) {
      padding: 0 15px;
      align-items: flex-start;
      padding-top: 20px;

      &.buying-panel--has-buy-button {
        // height: 192px;
        height: auto;
        padding-bottom: 10px;
      }
    }
  }

  .buying-panel__chosen-nfts {
    @media (max-width: 650px) {
      position: absolute;
      top: 27px;
    }

    img {
      max-height: 70px;
      margin-right: 10px;

      @media (max-width: 650px) {
        margin-right: 5px;
        max-width: 30px;
      }
    }
  }


  .buying-panel__content {
    display: flex;
    align-items: center;

    @media (max-width: 650px) {
      // flex-wrap: wrap;
      // justify-content: center;
      display: block;
      width: 100%;
      text-align: right;
    }
  }
  
  .buying-panel__currency-selector {
    margin-left: 30px;

    @media (max-width: 650px) {
      // float: right;
    }

    .el-radio-button__inner {
      height: 55px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;

      @media (max-width: 650px) {
        padding: 0 10px;
      }
    }
  }

  button.buying-panel__buy-button.el-button {
    margin-top: 0;
    margin-left: 30px;

    @media (max-width: 650px) {
      margin: 15px auto 0;
      display: block;
    }
  }

  .nct-popup {

    .el-dialog {
      max-width: 100%;
    }

    .el-dialog__body {
      word-break: initial;
    }

    .el-collapse-item__arrow {
      display: none;
    }

    .el-collapse-item__header {
      font-weight: bold;
      font-size: 20px;
      margin-top: 10px;
      font-family: 'Inconsolata', monospace;

      // element disabled
      @at-root .el-collapse-item.is-disabled .el-collapse-item__header {
        color: #303133;
        cursor: default;
      }

      @at-root .el-collapse-item.my-disabled .el-collapse-item__header {
        color: #bbb;
        cursor: not-allowed;
      }

      &:before {
        content: "1";
        border: 1px solid;
        border-radius: 50%;
        margin-right: 10px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        /* opacity: 0.95; */
        font-weight: normal;

        // https://stackoverflow.com/questions/16086377/scss-extend-a-nested-selector-and-override-the-nested-rulesets
        @at-root .nct-popup__second-collapse .el-collapse-item__header:before {
          content: "2";
        }
      }
      
    }

    p {
      margin: 10px 0 20px;
      font-size: 15px;
    }

    button.el-button {
      min-width: auto;
      padding: 7px 30px;
      margin: 0;
      margin-bottom: 10px;
    }
  }


  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////

  .main-content__examples-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;

    div {
      max-width: 200px;
    }

    img {
      max-width: 100%;
    }
  }

  .main-content__progress-section-wrapper {
    max-width: 600px;
    margin: auto;
    margin-bottom: 120px;
    padding: 0 10px;
  }

  .main-content__progress-section__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    h3 {
      font-size: 20px;
    }
  }

  .main-content__progress-section__progressbar {
    // background-color: #e9ecef;
    background: #ddd;

    position: relative;
    overflow: hidden;
    height: 30px;
    line-height: 30px;

    & > div {
      width: 50%;
      height: 100%;
      position: absolute;
      left: 0;
      z-index: 1;

      // background: #ff04b4;
      background: #000;
    }

    span {
      color: #fff;
      font-size: 15px;
      margin-left: 10px;
      z-index: 2;
      position: relative;
      font-size: 12px;
      font-weight: bold;
    }
  } // .main-content__progress-section__progressbar








  button.el-button {
    padding: 12px 50px;
    background: #000;
    // background: #ff04b4;
    position: relative;
    line-height: 30px;
    border: 0;

    border-radius: 0;
    font-size: 15px;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    margin-top: 10px;
    text-align: left;
    transition: all .5s;

    font-weight: 700;
    letter-spacing: 1px;
    min-width: 190px;

    color: #fff;

    border: 1px solid #000;
    &:hover {
      // color: #fff;
      // background: #000;
      // background-color: #181818;

      background: #fff;
      color: #000;
      border-color: #000;
    }

    &.is-disabled,
    &.is-disabled:hover {
      background: #ff04b4;
      color: #fff;
      opacity: 0.5;
    }

    &.address-connected {
      cursor: default;
      &:hover, &:active, &:focus {
        background: #000;
        color: #fff;
        border-color: #000;
      }
    }

    img.button__metamask-image {
      max-width: 40px;
      position: absolute;
      left: -11px;
      top: -10px;
    }
  }
</style>
